import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { Router, RouterModule } from '@angular/router';
import { TokenStorageService } from '../../../services/token-storage.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserSettings } from '../../../model/user-settings.model';
import { environment } from '../../../../environments/environment';
import { initCollapses, initDropdowns } from 'flowbite';
import { Airline } from '../../../model/airline.model';
import { ConfigService } from '../../../services/config.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Config, ConfigResponse } from '../../../model/config.model';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, NzDropDownModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit {
  darkMode: boolean = false;
  language: string = "EN";
  loading: boolean = false;
  appURL = environment.appURL;
  initials: string = "";
  name: string = "";
  email: string = "";
  airline = {} as Airline;
  config = {} as Config;
  constructor(
    private readonly tokenService: TokenStorageService,
    private readonly translateService: TranslateService,
    private readonly message: NzMessageService,
    private readonly localStorageService: LocalStorageService,
    private readonly configService: ConfigService,
    private readonly renderer: Renderer2,
    private readonly router: Router) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      initCollapses();
      initDropdowns();
    }, 1000);
    let userSettings: UserSettings;
    this.airline = this.tokenService.getUser().airline;
    if (this.localStorageService.getUserSettings() == null) {
      userSettings = {
        darkMode: false,
        language: 'EN',
      }
      this.localStorageService.saveUserSettings(JSON.stringify(userSettings));
    } else {
      userSettings = JSON.parse(this.localStorageService.getUserSettings());
    }
    this.darkMode = userSettings.darkMode;
    this.darkMode ? document.body.classList.add('dark') : document.body.classList.remove('dark');
    this.language = userSettings.language;
    this.translateService.setDefaultLang(this.language.toLowerCase());
    const user = this.tokenService.getUser();
    if (user) {
      this.name = user.firstName + ' ' + user.lastName;
      this.email = user.email;
      this.initials = user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
    }
    this.getConfig();
  }

  getConfig = () => {
    this.loading = true;
    this.configService.getConfig().subscribe({
      next: (response: ConfigResponse) => {
        this.config = response.data;
        this.loading = false;
        this.initializePendo();
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get('Error in fetching data', {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  initializePendo = (): void => {
    const scriptContent = "" +
      " (function(apiKey){ " +
      "     (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[]; " +
      "     v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){ " +
      "         o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]); " +
      "         y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js'; " +
      "         z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo'); " +
      "})('" + this.config.pendoWebKey + "');" +
      "   pendo.initialize({" +
      "     visitor: {" +
      "       id: " + this.tokenService.getUser().id +
      "     }," +
      "     account: {" +
      "       id: " + this.airline.airlineNumber +
      "     }," +
      "   });" +
      "";
    this.addInlineScript(scriptContent);
  }

  addInlineScript(scriptContent: string) {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `${scriptContent};`;
    this.renderer.appendChild(document.body, script);
  }

  onDarkModeClicked = (): void => {
    this.darkMode = !this.darkMode;
    this.darkMode ? document.body.classList.add('dark') : document.body.classList.remove('dark');
    this.localStorageService.saveDarkMode(this.darkMode);
  }
  logout = (): void => {
    this.tokenService.signOut();
  }

  goToMyProfile = (): void => {
    this.router.navigate(["mccplus/myProfile"]);
  }

  switchLanguage = (language: string): void => {
    this.language = language;
    this.translateService.use(this.language.toLowerCase());
    this.localStorageService.saveLanguage(this.language);
  }


}
