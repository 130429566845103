<app-logo-header></app-logo-header>

<app-page-loading [loading]="loading"></app-page-loading>

<section class="bg-white dark:bg-gray-800 mb-4">

    <div class="h-screen flex justify-center sm:flex-row">
        <div>
            <div class="mt-20">
                <app-back-button></app-back-button>
            </div>
            <div *ngIf="!submitted">
                <div class="p-4 space-y-0 sm:flex sm:space-y-0 sm:space-x-4">
                    <h2 class="mb-0 text-xl font-bold text-gray-900 dark:text-white">{{'Register' | translate}}</h2>
                </div>

                <div class=" p-4 md:p-5 space-y-4 text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <div *ngIf="!this.email">
                        <form [formGroup]="registerFormStep1">
                            <div>
                                <label for="email"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Please
                                    enter your corporate email address' |
                                    translate}}</label>
                                <input type="email" name="email" id="email" formControlName="email"
                                    class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="">
                                <p *ngIf="!registerFormStep1.get('email')?.valid && registerFormStep1.get('email')?.touched"
                                    class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Email is required' | translate}}</p>
                            </div>
                            <div class="mt-20 flex items-center justify-center">
                                <re-captcha
                                [siteKey]="this.captchaSiteKey"
                                (resolved)="onCaptchaResolved($event)">
                              </re-captcha>
                            </div>
                        </form>
                       
                        <div
                            class="mt-20 flex items-center justify-center p-2 md:p-3 space-x-3 rtl:space-x-reverse  border-gray-200 rounded-b dark:border-gray-600">
                            <button (click)="next()" type="button"
                                class="text-white bg-blue-700 hover:bg-blue-800font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ">
                                {{'Next' | translate }}</button>
                        </div>
                    </div>

                    <div *ngIf="this.email" class="max-w-xl">
                            <form [formGroup]="registerForm">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                                    <div class="col-span-1 sm:col-span-2">
                                        <label for="email"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Email'
                                            |
                                            translate}}</label>
                                        <p class="text-sm font-medium ml-2 m-y-4 text-gray-900 dark:text-white">
                                            {{this.registerFormStep1.value.email}}</p>
                                    </div>
                                    
                                    <div>
                                        <label for="username"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Username'
                                            |
                                            translate}}</label>
                                        <input type="text" name="username" id="username" formControlName="username"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="" required="">
                                        <p *ngIf="!registerForm.get('username')?.valid && registerForm.get('username')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'User Name is required' | translate}}</p>
                                    </div>
                                    <div>
                                        <label for="firstName"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*
                                            {{'First Name' | translate}}</label>
                                        <input type="text" name="firstName" id="firstName" formControlName="firstName"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="" required="">
                                        <p *ngIf="!registerForm.get('firstName')?.valid && registerForm.get('firstName')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'First Name is required' | translate}}</p>
                                    </div>

                                    <div>
                                        <label for="lastName"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*
                                            {{'Last Name' | translate}}</label>
                                        <input type="text" name="lastName" id="lastName" formControlName="lastName"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="" required="">
                                        <p *ngIf="!registerForm.get('lastName')?.valid && registerForm.get('lastName')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Last Name is required' | translate}}</p>
                                    </div>

                                    <div>
                                        <label for="employeeId"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*
                                            {{'Employee ID' | translate}}</label>
                                        <input type="text" name="employeeId" id="employeeId" formControlName="employeeId"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="" required="">
                                        <p *ngIf="!registerForm.get('employeeId')?.valid && registerForm.get('employeeId')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Employee ID is required' | translate}}</p>
                                    </div>

                                    <div>
                                        <label for="contactJobTitle"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Job Title' | translate}}</label>
                                        <input type="text" name="contactJobTitle" id="contactJobTitle"
                                            formControlName="contactJobTitle"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="" required="">
                                        <p *ngIf="!registerForm.get('contactJobTitle')?.valid && registerForm.get('contactJobTitle')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Job Title is required' | translate}}</p>
                                    </div>

                                    <div>
                                        <label for="contactCellPhone"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{'Phone' |
                                            translate}}</label>
                                        <input type="text" name="contactCellPhone" id="contactCellPhone"
                                            formControlName="contactCellPhone"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="">
                                        <p *ngIf="!registerForm.get('contactCellPhone')?.valid && registerForm.get('contactCellPhone')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Phone is required' | translate}}
                                        </p>
                                    </div>

                                    <div>
                                        <label for="contactTitle"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Title' |
                                            translate}}</label>
                                        <select id="contactTitle" formControlName="contactTitle"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                                <option value="">{{'Please select' | translate}}</option>
                                                <option value="Dr">Dr</option>
                                                <option value="Miss">Miss</option>
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Ms">Mrs</option>
                                            </select>
                                        <p *ngIf="!registerForm.get('contactTitle')?.valid && registerForm.get('contactTitle')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Title is required' | translate}}</p>
                                    </div>                                                        
                                    <div>
                                        <label for="country"
                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*{{'Country'
                                            |
                                            translate}}</label>
                                        <select id="country" formControlName="country"
                                            class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                            <option value="">{{'Please select' | translate}}</option>
                                            <option *ngFor="let elem of countries" [value]="elem.countryCode">
                                                {{elem.countryName}}
                                            </option>
                                        </select>
                                        <p *ngIf="!registerForm.get('country')?.valid && registerForm.get('country')?.touched"
                                            class="mt-2 text-sm text-red-600 dark:text-red-500">{{'Country is required' | translate}}</p>
                                    </div>

                                </div>
                            </form>
                        <div class="flex items-center justify-center p-2">
                            <p>
                                {{'By creating an account with Accommodations Plus International, you agree to abide our' | translate }} <a
                                    class="text-blue-600" href='/assets/CrewCareTermsAndConditions.pdf'>{{'Terms of service' | translate }}</a>
                                {{'If the airline name displayed is not your employer, please contact your airline administrator. Do not submit this form.' | translate }}
                            </p>
                        </div>
                        <div
                            class="flex items-center justify-center p-2 md:p-3 space-x-3 rtl:space-x-reverse  border-gray-200 rounded-b dark:border-gray-600">
                            <button (click)="submitRegisterForm()" type="button"
                                class="text-white bg-blue-700 hover:bg-blue-800font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ">
                                {{'Register For API Hotels Airline' | translate }}</button>
                        </div>

                    </div>
                </div>

                <div class="p-4">
                    <div class="flex items-center justify-cente text-gray-900 dark:text-white">
                        <p>
                            {{'If you experience problems with your registration please contact' | translate }} <a class="text-blue-600"
                                href="mailto:ccsupport@apihotels.com">{{'ccsupport@apihotels.com'}}</a>
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="submitted">
                <h2 class="mb-0 text-xl font-bold text-gray-900 dark:text-white">{{'Thank you for registering for the My Crew Care site. You will receive an e-mail upon activation of your new account' | translate}}</h2>
            </div>
        </div>
    </div>

</section>