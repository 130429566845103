<app-page-loading [loading]="loading"></app-page-loading>

<section class="bg-gray-50 dark:bg-gray-900 h-screen">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
    <div class="flex flex-col justify-center">
      <h1
        class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
        {{'Login Title' | translate}}</h1>
      <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">{{'Login Subtitle' | translate}}</p>
      <a href="https://www.apiglobalsolutions.com/mycrewcare/"
        class="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center">{{'Login Link' | translate}}
        <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </a>
    </div>
    

      <div *ngIf="showLogin"
        class="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <h2 class="text-2xl font-bold text-gray-900 dark:text-white">
          {{'Sign in to MyCrewCare+' | translate}}
        </h2>
        <form class="mt-8 space-y-6" action="#">
          <div *ngIf="!showSignIn">
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{'Your email or username' | translate}}</label>
            <input [(ngModel)]="form.username" type="text" name="email" id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@company.com" required>
          </div>
          <div *ngIf="showSignIn">
            <label for="password" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">{{'Signing in with' | translate}} {{form.username}}
              </label>
            
          </div>
          <div *ngIf="showSignIn">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{'Your password' | translate}}
              </label>
            <input type="password" name="password" autocomplete="off" id="password" placeholder="Password" [(ngModel)]="form.password"
              [type]="hide ? 'password' : 'text'"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required>
          </div>
          <div class="flex items-start">
            <div class="flex items-center h-5">
              <input id="remember" aria-describedby="remember" name="remember" type="checkbox"
                class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                required>
            </div>
            <div class="ms-3 text-sm">
              <label for="remember" class="font-medium text-gray-500 dark:text-gray-400">{{'Stay Signed In' | translate}}</label>
            </div>
            <a (click)="showLogin = false; showReset = true;"
              class="ms-auto text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">{{'Forgot Password?' | translate}}</a>
          </div>

          <div *ngIf="showCaptcha" class="mt-20 flex items-center justify-center">
            <re-captcha #recaptchaRef
              [siteKey]="this.captchaSiteKey"
              (resolved)="onCaptchaResolved($event)">
             </re-captcha>
          </div>

          <div class="md:flex gap-2">
            <button type="button" *ngIf="!showSignIn"
            class="w-full md:w-auto items-center px-5 py-2.5 mt-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-gray-200  hover:bg-blue-800  dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            (click)="next()" [disabled]="(loading && errorMessage === '') || (!loading && errorMessage !== '')">{{'Next' | translate}}</button>
            
            <button type="button" *ngIf="showSignIn"
            class="w-full md:w-auto items-center px-5 py-2.5 mt-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-gray-200  hover:bg-blue-800  dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              (click)="login()" [disabled]="(loading && errorMessage === '') || (!loading && errorMessage !== '')">{{'Sign in' | translate}}</button>

            <button type="button" *ngIf="showSignIn"
              class="w-full md:w-auto items-center px-5 py-2.5 mt-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100  dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              (click)="back()" [disabled]="(loading && errorMessage === '') || (!loading && errorMessage !== '')">{{'Back' | translate}}</button>
          </div>
          <div class="text-sm font-medium text-gray-900 dark:text-white">
            {{'New User?' | translate}} <a routerLink="/register" class="text-blue-600 hover:underline dark:text-blue-500">{{'Register here' | translate}}</a>
          </div>
          
        </form>
      </div>


      <div *ngIf="!showLogin && showReset"
        class="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">

        <button (click)="showLogin = true; showReset =false;" type="button" class="bg-white w-full flex items-center justify-center px-5 py-2 mb-2 text-sm text-gray-700 transition-colors duration-200   rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-700 dark:bg-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:border-gray-700">
          <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
          </svg>
          <span>{{'Go Back to Login' | translate}}</span>
        </button>

        <div>
          <h2 class="text-xl font-bold text-gray-900 dark:text-white">{{'Lost your Password?' | translate}}</h2>
          <p class="text-gray-900 dark:text-white">{{'Enter the email or username associated with your account and we’ll send you a link to reset your password.' | translate}}</p>
        </div>
        <form class="mt-8 space-y-6" action="#">

          <input type="text" name="emailOrUsername" id="emailOrUsername" placeholder="" [(ngModel)]="instructionForm.emailOrUsername"               
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required>

          <button type="button"
            class="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            (click)="sendInstructions()">{{'Send Instructions' | translate}}</button>            
        </form>
      </div>

    
  </div>
</section>