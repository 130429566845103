import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoginAuthService } from '../../../../services/login-auth-service';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { PageLoadingComponent } from '../../../common/page-loading/page-loading.component';
import { PingOneService } from '../../../../services/pingone.service';
import { PingOneToken, UserInfo } from '../../../../model/pingone.model';
import _ from 'lodash';
import moment from 'moment';
import { PASSWORD_NOT_CHANGED_SINCE_DAYS, SERVER_DATE_FORMAT_CONSTANT, USER_ROLE_CONSTANT } from '../../../../common/constants/constant';
import { generateRandomValue } from '../../../../utils/common-utils';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-ping-one-login',
  standalone: true,
  imports: [CommonModule, FormsModule, NzButtonModule, ToastrModule, HttpClientModule, NzFormModule, NzInputModule, NzInputNumberModule, NzSpinComponent, PageLoadingComponent, TranslateModule, RouterModule],
  templateUrl: './ping-one-login.component.html',
  styleUrl: './ping-one-login.component.scss'
})
export class PingOneLoginComponent {
  code: string = '';
  accessToken: string = '';
  token = {} as PingOneToken | undefined;
  userInfo = {} as UserInfo;
  loading: boolean = false;
  roles: any = [];
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: LoginAuthService,
    private readonly pingOneService: PingOneService,
    private readonly tokenService: TokenStorageService,
    private readonly translateService: TranslateService,
    private readonly configService: ConfigService,
    private readonly message: NzMessageService
  ) {
  }
  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.code = params['code'];
        if (!this.code) {
          const state = generateRandomValue();
          const nonce = generateRandomValue();
          this.pingOneService.authorize(state, nonce);
          return;
        }
        if (_.isEmpty(this.token)) {
          this.pingOneService.login(this.code).subscribe({
            next: (data: any) => {
              this.tokenService.saveToken(data.accessToken);
              this.tokenService.saveRefreshToken(data.refreshToken);
              this.tokenService.saveUser(data);
              this.roles = this.tokenService.getUser().roles;
              this.loadLandingPage(data);
            },
            error: (error: string) => {
              this.translateService.get(error, {}).subscribe((res: string) => {
                this.message.error(res);
              });
            }
          });
        }
      });
  }

  loadLandingPage = (data: any): void => {
    const lastPasswordChangedDate = moment(data.lastPasswordChangedDate, SERVER_DATE_FORMAT_CONSTANT);
    if (data.airline.baseAirportOnUserProfile && !data.baseAirport) {
      this.router.navigate(['/mccplus/myProfile']);
    } else if (!data.airline.initiateSso && moment().diff(lastPasswordChangedDate, "days") > PASSWORD_NOT_CHANGED_SINCE_DAYS) {
      this.router.navigate(['/mccplus/changePassword']);
    }


    if (this.authService.isAuthorized([
      USER_ROLE_CONSTANT.INTERNAL_ADMIN]) || (data.airline.enableFeedbacks == 1 && this.authService.isAuthorized([
        USER_ROLE_CONSTANT.EXTERNAL_ADMIN,
        USER_ROLE_CONSTANT.CREW_MEMBER,
        USER_ROLE_CONSTANT.CREW_MEMBER_PLUS,
        USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN]))) {
      this.router.navigate(['/mccplus/feedback']);
    } else if (data.airline.myTravelLink == 1 && this.authService.isAuthorized([
      USER_ROLE_CONSTANT.CREW_MEMBER,
      USER_ROLE_CONSTANT.CREW_MEMBER_PLUS])) {
      this.router.navigate(['/mccplus/myTravel']);
    } else if (data.airline.enableFeaturedHotels == 1 && this.authService.isAuthorized([
      USER_ROLE_CONSTANT.CREW_MEMBER,
      USER_ROLE_CONSTANT.CREW_MEMBER_PLUS,
      USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN,
      USER_ROLE_CONSTANT.EXTERNAL_REPORTS_USER,
    ])) {
      this.router.navigate(['/mccplus/featuredHotels']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.DIL_AGENT])) {
      this.router.navigate(['/mccplus/searchDailyIncidentLog']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_SERVICES])) {
      this.router.navigate(['/mccplus/cityAndHotels']);
    } 
  }
}
