import { Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ProtectedLayoutComponent } from './layouts/protected-layout/protected-layout.component';
import { AccessDeniedComponent } from './pages/auth/access-denied/access-denied.component';
import { PageNotFoundComponent } from './pages/common/page-not-found/page-not-found.component';
import { ConsoleLayoutComponent } from './layouts/console-layout/console-layout.component';
import { authGuard } from './guards/auth.guard';
import { USER_ROLE_CONSTANT } from './common/constants/constant';
import { PingOneLoginComponent } from './pages/ping/sso/ping-one-login/ping-one-login.component';
import { PingOneLogoutComponent } from './pages/ping/sso/ping-one-logout/ping-one-logout.component';

export const routes: Routes = [
    {
        path: 'mccplus',
        canActivate: [authGuard],
        component: ProtectedLayoutComponent,
        loadChildren: () => import('./pages/protected/protected.module').then(mod => mod.ProtectedModule),
    },
    {
        path: 'console',
        canActivate: [authGuard],
        component: ConsoleLayoutComponent,
        loadChildren: () => import('./pages/protected/console/console.module').then(mod => mod.ConsoleModule),
        data: {
            allowedRoles: [USER_ROLE_CONSTANT.ADMINISTRATOR],
        }
    },
    {
        path: 'set-password',
        loadChildren: () => import('./pages/auth/set-password/set-password.module')
        .then(mod => mod.ResetPasswordModule)
      },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'accessDenied',
        component: AccessDeniedComponent
    },
    {
        path: 'pageNotFound',
        component: PageNotFoundComponent
    },
    {
        path: 'ping/sso/login',
        component: PingOneLoginComponent
    },
    {
        path: 'ping/sso/logout',
        component: PingOneLogoutComponent
    }
];
