import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BackButtonComponent } from '../../../common/ui/buttons/back-button/back-button.component';
import { PageLoadingComponent } from '../../common/page-loading/page-loading.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '../../../services/user.service';
import { CountryService } from '../../../services/country.service';
import { Country, CountryPagedResponse } from '../../../model/country.model';
import { LogoHeaderComponent } from "../../../common/ui/logo-header/logo-header.component";
import { AirlineService } from '../../../services/airline.service';
import { Airline } from '../../../model/airline.model';
import { RecaptchaModule } from 'ng-recaptcha';
import { environment } from '../../../../environments/environment';
import { LoginAuthService } from '../../../services/login-auth-service';
import { RecaptchaResponse } from '../../../model/recaptcha.model';

@Component({
  selector: 'app-register',
  standalone: true,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  imports: [CommonModule, NzTabsModule, PageLoadingComponent, TranslateModule, RouterModule, BackButtonComponent, ReactiveFormsModule, LogoHeaderComponent, RouterModule, RecaptchaModule]
})
export class RegisterComponent {
  registerForm: FormGroup;
  registerFormStep1: FormGroup;
  loading: boolean = false;
  countries: Country[] = [];
  airline = {} as Airline;
  editData: any = {};
  email: string = '';
  submitted: boolean = false;
  recaptchaResponse: string | null = null;
  captchaSiteKey = environment.captchaSiteKey;
  constructor(
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly message: NzMessageService,
    private readonly countryService: CountryService,
    private readonly airlineService: AirlineService,
    private readonly authService: LoginAuthService,
    private readonly translateService: TranslateService) {

    this.registerForm = this.fb.group({
      language: ['en'],
      employeeId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactJobTitle: ['', Validators.required],
      username: ['', Validators.required],
      contactCellPhone: [''],
      contactTitle: ['', Validators.required],
      country: ['', Validators.required]
    });
    this.registerFormStep1 = this.fb.group({
      email: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.countryService.getAllCountries().subscribe({
      next: (response: CountryPagedResponse) => {
        this.countries = response.data.countries;
        this.loading = false;
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get('Error in fetching data', {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  next() {
    if (!this.recaptchaResponse) {
      this.translateService.get("Please select I'm not a robot", {}).subscribe((res: string) => {
        this.message.error(res);
      });
      return;
    }
    if (!this.registerFormStep1.valid) {
      this.loading = false;
      for (const i in this.registerFormStep1.controls) {
        if (this.registerFormStep1.controls.hasOwnProperty(i)) {
          this.registerFormStep1.controls[i].markAsDirty();
          this.registerFormStep1.controls[i].updateValueAndValidity();
        }
      }
      this.translateService.get('Values entered are not valid', {}).subscribe((res: string) => {
        this.message.error(res);
      });
      return;
    }
    this.loading = true;
    this.authService.verifyCaptcha(this.recaptchaResponse).subscribe({
      next: (response: RecaptchaResponse) => {
        this.loading = false;
        if (!response.success) {
          this.translateService.get("Problem validating the captcha. Please refresh the page.", {}).subscribe((res: string) => {
            this.message.error(res);
          });
          return;
        }
        this.getAirlineDomains();
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get("Error in fetching data", {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  getAirlineDomains = (): void => {
    this.loading = true;
    this.airlineService.getAirlineByEnterDomains(this.registerFormStep1.value.email).subscribe({
      next: (response: any) => {
        this.loading = false;
        if (!response.success) {
          this.translateService.get("Error in fetching data", {}).subscribe((res: string) => {
            this.message.error(res);
          });
          return;
        }
        if (!response.data) {
          this.translateService.get("The email address is not supported", {}).subscribe((res: string) => {
            this.message.error(res);
          });
          return;
        }
        this.airline = response.data;
        this.email = this.registerFormStep1.value.email;
        if (this.airline.initiateSso && this.airline.ssoPartnerURL.length !== 0) {
          document.location.href = this.airline.ssoPartnerURL;
        }
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get("Error in fetching data", {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  submitRegisterForm = (): void => {
    this.loading = true;
    if (!this.registerForm.valid) {
      this.loading = false;
      for (const i in this.registerForm.controls) {
        if (this.registerForm.controls.hasOwnProperty(i)) {
          this.registerForm.controls[i].markAsDirty();
          this.registerForm.controls[i].updateValueAndValidity();
        }
      }
      this.translateService.get('Values entered are not valid', {}).subscribe((res: string) => {
        this.message.error(res);
      });
      return;
    }
    this.editData.airlineID = this.airline.airlineID;
    this.editData.airlineIdInt = this.airline.airlineNumber;
    this.editData.language = this.registerForm.value.language;
    this.editData.employeeId = this.registerForm.value.employeeId;
    this.editData.firstName = this.registerForm.value.firstName;
    this.editData.lastName = this.registerForm.value.lastName;
    this.editData.username = this.registerForm.value.username;
    this.editData.contactJobTitle = this.registerForm.value.contactJobTitle;
    this.editData.contactCellPhone = this.registerForm.value.contactCellPhone;
    this.editData.contactTitle = this.registerForm.value.contactTitle;
    this.editData.email = this.registerFormStep1.value.email;
    this.editData.country = this.registerForm.value.country;

    this.userService.registerUser(this.editData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.translateService.get('User added successfully', {}).subscribe((res: string) => {
            this.message.success(res);
          });
        } else {
          this.translateService.get("Error in adding user", {}).subscribe((res: string) => {
            this.message.error(res);
          });
        }
        this.loading = false;
        this.submitted = true;
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get("Error in adding user", {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  onCaptchaResolved(captchaResponse: string | null) {
    this.recaptchaResponse = captchaResponse;
  }

}
