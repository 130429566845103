<div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
  
 <app-header></app-header>

  <aside id="logo-sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidebar">
    <div class="flex flex-col h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
      <div>
        <ul class="space-y-2 font-medium">
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="airlines"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                airlines
                </span>
              <span class="ms-3">{{'Airlines' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="airports"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                connecting_airports
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'Airports' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="branches"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                call_split
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'Branches' | translate}}</span>
            </a>
          </li>       
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="feedbackTopics"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                feedback
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'Feedback Topics' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="emailTemplates"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                mail
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'Email Templates' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="myTravelDemo"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                transition_slide
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'My Travel Demo' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="googleLogs"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg
                class="flex-shrink-0 w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                <path
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'Google Logs' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="userActivityReport"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                person
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'User Activity' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="inAppReviewReport"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                rate_review
                </span>
              <span class="flex-1 ms-3 whitespace-nowrap">{{'In-App Review Report' | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <ul class="space-y-2 font-medium ml-2">
          <li>
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="/mccplus/featuredHotels"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                arrow_back
                </span>
              <span class="ms-3">{{'Back to' | translate}} MCC</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </aside>


  <main class="p-4 md:ml-64 pt-20 h-full">
    <router-outlet></router-outlet>
  </main>
</div>