import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { initDropdowns } from 'flowbite';
import { HeaderComponent } from "../../common/ui/header/header.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-console-layout',
  standalone: true,
  templateUrl: './console-layout.component.html',
  styleUrl: './console-layout.component.scss',
  imports: [CommonModule, RouterModule, HeaderComponent, TranslateModule]
})
export class ConsoleLayoutComponent implements OnInit {
  ngOnInit(): void {
    initDropdowns();
  }
}
