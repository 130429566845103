<nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start rtl:justify-end">
        <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
          <span class="sr-only">Open sidebar</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
            </path>
          </svg>
        </button>
        <a href="{{appURL}}" class="flex ms-2 md:me-24">
          <img *ngIf="darkMode" src="../../../assets/api-logo-dark.png?1" class="h-8 me-3" alt="MCC Crew Care Logo" />
          <img *ngIf="!darkMode" src="../../../assets/api-logo.png?1" class="h-9 me-2" alt="MCC Crew Care Logo" />

          <span class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">{{'My Crew Care'
            | translate}}</span>

        </a>
      </div>
      <div class="flex items-center">
        <div class="flex items-center ms-3 gap-2">

         
          <div
            class="text-gray-500 inline-flex items-center justify-center dark:text-gray-400 hover:bg-gray-100 w-10 h-10 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
            <button class="flex transition-transform hover:scale-125 align-middle" (click)="onDarkModeClicked()">
              <span *ngIf="!darkMode" class="material-icons">dark_mode</span>
              <span *ngIf="darkMode" class="material-icons">light_mode</span>
            </button>
          </div>

          <div>
            <a nz-dropdown [nzDropdownMenu]="languageMenu" [nzPlacement]="'bottomLeft'">
              <div
                class="relative inline-flex items-center justify-center dark:text-gray-400 hover:bg-gray-100 w-10 h-10 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
                <span class="font-medium text-gray-600 dark:text-gray-300">{{language}}</span>
              </div>
            </a>
            <nz-dropdown-menu #languageMenu="nzDropdownMenu">
              <ul nz-menu class="bg-gray-50 dark:bg-gray-700">
                <li
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="switchLanguage('EN')">
                  <div class="inline-flex items-center">
                    {{'English' | translate}}
                  </div>
                </li>

                <li
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="switchLanguage('FR')">
                  <div class="inline-flex items-center">
                    {{'French' | translate}}
                  </div>
                </li>

                <li
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="switchLanguage('PT')">
                  <div class="inline-flex items-center">
                    {{'Portuguese' | translate}}
                  </div>
                </li>

                <li
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="switchLanguage('ES')">
                  <div class="inline-flex items-center">
                    {{'Spanish' | translate}}
                  </div>
                </li>

              </ul>
            </nz-dropdown-menu>
          </div>

          
          <div>
            <a nz-dropdown [nzDropdownMenu]="userMenu">
              <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300">{{initials}}</span>
              </div>
            </a>
            <nz-dropdown-menu #userMenu="nzDropdownMenu">
              <ul nz-menu class="bg-gray-50 dark:bg-gray-700">               
                <li *ngIf="this.airline.userProfile"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="goToMyProfile()">{{'My Profile' | translate}}</li>
                <li
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  nz-menu-item (click)="logout()">{{'Sign out' | translate}}</li>
              </ul>
            </nz-dropdown-menu>
          </div>

        </div>
      </div>
    </div>
  </div>
</nav>