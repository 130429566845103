import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { provideToastr } from 'ngx-toastr';
import { en_US, provideNzI18n, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { authInterceptorProviders } from './pages/common/interceptors/auth.interceptor';
import { ErrorInterceptor } from './pages/common/interceptors/error.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

registerLocaleData(en);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return  new  TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimations(),
  provideHttpClient(),
  provideToastr(),
  provideNzI18n(en_US),
  importProvidersFrom(FormsModule),
  importProvidersFrom(HttpClientModule),
  importProvidersFrom(TranslateModule.forRoot(provideTranslation())),
  provideAnimations(),
    authInterceptorProviders, { provide: NZ_I18N, useValue: en_US }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
  ]
};
