import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-logo-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo-header.component.html',
  styleUrl: './logo-header.component.scss'
})
export class LogoHeaderComponent {
  appURL = environment.appURL;

}
