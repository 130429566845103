import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../common/ui/header/header.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { initDrawers, initCollapses, initTooltips } from 'flowbite';
import { USER_ROLE_CONSTANT } from '../../common/constants/constant';
import { CheckRolesToShowDirective } from '../../directives/check-role-to-show.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Airline, AirlineResponse } from '../../model/airline.model';
import { AirlineService } from '../../services/airline.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoginAuthService } from '../../services/login-auth-service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-protected-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, NzDropDownModule, CheckRolesToShowDirective, TranslateModule, RouterModule, TranslateModule],
  templateUrl: './protected-layout.component.html',
  styleUrl: './protected-layout.component.scss'
})
export class ProtectedLayoutComponent implements OnInit {
  userRoles = USER_ROLE_CONSTANT;
  airline = {} as Airline;
  loading: boolean = false;
  appURL = environment.appURL;
  minimizeDrawer = false;
  constructor(private readonly router: Router,
    private readonly airlineService: AirlineService,
    private readonly message: NzMessageService,
    private readonly authService: LoginAuthService,
    private readonly translateService: TranslateService,
    private readonly tokenService: TokenStorageService) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      initDrawers();
      initCollapses();
      initTooltips();
    }, 1000);

    this.airlineService.getUserAirline(this.tokenService.getUser().airlineIdInt).subscribe({
      next: (response: AirlineResponse) => {
        this.loading = true;
        this.airline = response.data;
      },
      error: (error: string) => {
        this.loading = false;
        this.translateService.get('Error in fetching data', {}).subscribe((res: string) => {
          this.message.error(res);
        });
      }
    });
  }

  onNewFeedback(): void {
    this.router.navigate(['/mccplus/provideFeedback']);
  }

  showMainMenu(): boolean {
    return this.showMyTravel() || this.showFindCrewTravel() || this.showFeedback() || this.showMyCalendar() || this.showDailyIncidentLogs() || this.showReports();
  }

  showHotelMenu(): boolean {
    return this.showCityAndHotelInformation() || this.showTransportCompanies() || this.showBookHotel();
  }


  showDailyIncidentLogs(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD, USER_ROLE_CONSTANT.DIL_AGENT])) {
      return true;
    }
    return false;
  }

  showReports(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN,
    USER_ROLE_CONSTANT.VERTICAL_LEAD,
    USER_ROLE_CONSTANT.EXTERNAL_ADMIN,
    USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN,
    USER_ROLE_CONSTANT.EXTERNAL_REPORTS_USER
    ])) {
      return true;
    }
    return false;
  }

  showFindCrewTravel(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD])) {
      return true;
    }
    if (this.airline.findCrewTravelLink && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN])) {
      return true;
    }
    return false;
  }

  showFeatureHotels(): boolean {
    if (this.airline.enableFeaturedHotels && this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN,
    USER_ROLE_CONSTANT.EXTERNAL_ADMIN,
    USER_ROLE_CONSTANT.VERTICAL_LEAD,
    USER_ROLE_CONSTANT.CREW_MEMBER,
    USER_ROLE_CONSTANT.CREW_MEMBER_PLUS,
    USER_ROLE_CONSTANT.EXTERNAL_REPORTS_USER,
    USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN])) {
      return true;
    }
    return false;
  }
  showMyTravel(): boolean {
    if (this.airline.myTravelLink && this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_MEMBER,
    USER_ROLE_CONSTANT.CREW_MEMBER_PLUS])) {
      return true;
    }
    return false;
  }

  showFeedback(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD])) {
      return true;
    }
    if (this.airline.enableFeedbacks && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN,
    USER_ROLE_CONSTANT.CREW_MEMBER,
    USER_ROLE_CONSTANT.CREW_MEMBER_PLUS,
    USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN])) {
      return true;
    }
    return false;
  }
  showNewFeedback(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD, USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN])) {
      return true;
    }
    if (this.airline.enableFeedbacks && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN, USER_ROLE_CONSTANT.CREW_MEMBER, USER_ROLE_CONSTANT.CREW_MEMBER_PLUS])) {
      return true;
    }
    return false;
  }


  showAdmin(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.ADMINISTRATOR,
    USER_ROLE_CONSTANT.VERTICAL_LEAD,
    USER_ROLE_CONSTANT.INTERNAL_ADMIN])) {
      return true;
    }
    if (this.showUserAdministration()) {
      return true;
    }
    return false;
  }
  showUserAdministration(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD])) {
      return true;
    }
    if (!this.airline.initiateSso && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN])) {
      return true;
    }
    return false;
  }

  showMyCalendar(): boolean {
    if (this.airline.enableMyCalendar && this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_MEMBER])) {
      return true;
    }
    return false;
  }

  showBookHotel(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN,
    USER_ROLE_CONSTANT.VERTICAL_LEAD,
    USER_ROLE_CONSTANT.INTERNAL_REPORTS_ADMIN])) {
      return true;
    }
    if (this.airline.bookaHotelLink && this.airline.hotelsExpressURL != null
      && this.authService.isAuthorized([
        USER_ROLE_CONSTANT.EXTERNAL_ADMIN,
        USER_ROLE_CONSTANT.CREW_SERVICES,
        USER_ROLE_CONSTANT.CREW_MEMBER,
        USER_ROLE_CONSTANT.CREW_MEMBER_PLUS,
      ])) {
      return true;
    }
    return false;
  }

  showCityAndHotelInformation(): boolean {
    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN, USER_ROLE_CONSTANT.VERTICAL_LEAD])) {
      return true;
    }
    if (this.airline.enableCityAndTransportInfo && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN, USER_ROLE_CONSTANT.CREW_MEMBER, USER_ROLE_CONSTANT.CREW_MEMBER_PLUS, USER_ROLE_CONSTANT.CREW_SERVICES])) {
      return true;
    }
    return false;
  }

  showTransportCompanies(): boolean {
    if (this.airline.enableCityAndTransportInfo
      && this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN, USER_ROLE_CONSTANT.CREW_MEMBER, USER_ROLE_CONSTANT.CREW_MEMBER_PLUS, USER_ROLE_CONSTANT.CREW_SERVICES, USER_ROLE_CONSTANT.DIL_AGENT])) {
      return true;
    }
    return false;
  }

  showConfiguration(): boolean {
    if (this.airline.enableConfiguration
      && this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_MEMBER, USER_ROLE_CONSTANT.CREW_MEMBER_PLUS])) {
      return true;
    }
    return false;
  }

  onBookHotel(): void {
    window.open(this.airline.hotelsExpressURL, "_blank");
  }

  onUserGuide(): void {
    window.open(this.appURL + "/Uploads/Public/UserGuides/InternalAdmin/AdminUserGuide.pdf", "_blank");
  }

  onPrivacyPolicy(): void {
    window.open(this.appURL + "/Uploads/Public/PrivacyPolicy/PrivacyPolicy_9_0_EN.pdf", "_blank");
  }

  onSignout = (): void => {
    this.tokenService.signOut();
  }

  onConsole(): void {
    window.open(this.appURL + "/console/airlines", "_blank");
  }

  onMinizeDrawer(): void {
    this.minimizeDrawer = !this.minimizeDrawer;
  }


}

