<div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
  <app-header></app-header>

  <aside id="logo-sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidebar">
    <div class="flex flex-col h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
      <div class="">
        <button *ngIf="showNewFeedback()" type="button" (click)="onNewFeedback()"
          class="flex-col w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-3.5 text-center inline-flex items-center  me-2 mb-2">
          {{'New Feedback' | translate}}
        </button>
        <h4 *ngIf="showMainMenu()" class="text-gray-900 dark:text-gray-400 !mt-2 !mb-2 !text-sm uppercase">{{'MAIN' | translate}}</h4>
        <ul class="space-y-2 font-medium ml-2">
          <li *ngIf="showFeatureHotels()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="featuredHotels"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                concierge
                </span>
              <span class="ms-3">{{'Featured Hotels' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showMyTravel()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="myTravel"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                travel_explore
                </span>
              <span class="flex-1 ms-3 ">{{'My Travel' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showFindCrewTravel()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="crewTravel" href=""
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                travel_explore
                </span>
              <span class="flex-1 ms-3">{{'Find Crew Travel' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showFeedback()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="feedback"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                chat
                </span>
              <span class="flex-1 ms-3 ">{{'Feedback' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showMyCalendar()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="myCalendar"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                calendar_month
                </span>
              <span class="flex-1 ms-3 ">{{'My Calendar' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showDailyIncidentLogs()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="searchDailyIncidentLog"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                info
                </span>
              <span class="flex-1 ms-3 ">{{'Daily Incident Log' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showReports()">
            <button type="button"
              class="flex items-center w-full p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              aria-controls="dropdown-reports" data-collapse-toggle="dropdown-reports">
              <span class="material-symbols-outlined">
                bar_chart
                </span>
              <span class="flex-1 ms-3 text-left rtl:text-right ">{{'Reports' | translate}}</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul id="dropdown-reports" class="hidden py-2 space-y-2">
              <li>
                <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="feedbackSummaryReport"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{{'Feedback Summary Report' | translate}} </a>
              </li>
              <li>
                <a href="#"
                  class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{{'Year To Date Complaints' | translate}} </a>
              </li>
              <li>
                <a href="#"
                  class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{{'Year To Date Compliments' | translate}} </a>
              </li>
              <li>
                <a href="#"
                  class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{{'Analysis of Hotel Feedbacks' | translate}} 
                  </a>
              </li>
            </ul>
          </li>
        </ul>
        <h4 *ngIf="showHotelMenu()" class="text-gray-900 dark:text-gray-400 !mt-5 !mb-2 !text-sm uppercase">{{'HOTEL' | translate}}</h4>
        <ul class="space-y-2 font-medium ml-2">
          <li  *ngIf="showCityAndHotelInformation()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="cityAndHotels"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                apartment
                </span>
              <span class="flex-1 ms-3 ">{{'City & Hotel Information' | translate}} </span>
            </a>
          </li>
          <li  *ngIf="showTransportCompanies()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="transportCompanies"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                airport_shuttle
                </span>
              <span class="flex-1 ms-3 ">{{'Transport Companies' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showBookHotel()">
            <a (click)="onBookHotel()"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                hotel
                </span>
              <span class="flex-1 ms-3 ">{{'Book Hotel' | translate}}</span>
            </a>
          </li>
        </ul>
        <h4 *ngIf="showAdmin()" class="text-gray-900 dark:text-gray-400 !mt-5 !mb-2 !text-sm uppercase">{{'ADMIN' | translate}}</h4>
        <ul class="space-y-2 font-medium ml-2">
          <li *appCheckRolesToShow="[ userRoles.INTERNAL_ADMIN]">
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="airlines"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                airlines
                </span>
              <span class="ms-3">{{'Airlines' | translate}}</span>
            </a>
          </li>
          <li *appCheckRolesToShow="[userRoles.INTERNAL_ADMIN, userRoles.VERTICAL_LEAD]">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="transportCompanies"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                airport_shuttle
                </span>
              <span class="flex-1 ms-3 ">{{'Transport Companies' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showUserAdministration()">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="searchUsers"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                manage_accounts
                </span>
              <span class="flex-1 ms-3 ">{{'User Administration' | translate}}</span>
            </a>
          </li>

          <li *appCheckRolesToShow="[userRoles.ADMINISTRATOR]">
            <a routerLinkActive="dark:bg-gray-700 bg-gray-100" routerLink="searchSupportRequests"
              [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                mail
                </span>
              <span class="flex-1 ms-3 ">{{'Support Requests' | translate}}</span>
            </a>
          </li>
        </ul>
        <h4 *ngIf="this.airline.userProfile || showConfiguration()" class="text-gray-900 dark:text-gray-400 !mt-5 !mb-2 !text-sm uppercase">{{'ACCOUNT' | translate}}</h4>
        <ul class="space-y-2 font-medium ml-2">
          <li *ngIf="this.airline.userProfile">
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="myProfile"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                person
                </span>
              <span class="ms-3">{{'My Profile' | translate}}</span>
            </a>
          </li>
          <li *ngIf="showConfiguration()">
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="configuration"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                settings
                </span>
              <span class="ms-3">{{'Configuration' | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <ul class="space-y-2 font-medium ml-2">
          <li *ngIf="this.airline.showContactUslink">
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="contactUs"
              [routerLinkActiveOptions]="{ exact: true }" href=""
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                contact_support
                </span>
              <span class="ms-3">{{'Contact Us' | translate}}</span>
            </a>
          </li>
          <li *appCheckRolesToShow="[userRoles.ADMINISTRATOR]">
            <a routerLinkActive="active dark:bg-gray-700 bg-gray-100" routerLink="/console/airlines"
            [routerLinkActiveOptions]="{ exact: true }"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                admin_panel_settings
                </span>
              <span class="ms-3">{{'Console' | translate}}</span>
            </a>
          </li>
          <li>
            <a data-tooltip-placement="right" (click)="onSignout()"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="material-symbols-outlined">
                logout
                </span>
              <span *ngIf="!this.minimizeDrawer" class="ms-3">{{'Sign Out' | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </aside>

  <main class="p-4 md:ml-64 h-full pt-20">
      <router-outlet></router-outlet>

  </main>
</div>