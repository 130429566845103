import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-ping-one-logout',
  standalone: true,
  imports: [CommonModule,RouterModule],
  templateUrl: './ping-one-logout.component.html',
  styleUrl: './ping-one-logout.component.scss'
})
export class PingOneLogoutComponent {
  constructor(
    private readonly router: Router) {
      this.router.navigate(["/"]);
   }
}
