import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { TokenStorageService } from '../../../services/token-storage.service';
import { LoginAuthService } from '../../../services/login-auth-service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Subscription } from 'rxjs';
import { PageLoadingComponent } from "../../common/page-loading/page-loading.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PASSWORD_NOT_CHANGED_SINCE_DAYS, SERVER_DATE_FORMAT_CONSTANT, USER_ROLE_CONSTANT } from '../../../common/constants/constant';
import { PingOneService } from '../../../services/pingone.service';
import { generateRandomValue } from '../../../utils/common-utils';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { environment } from '../../../../environments/environment';
import { RecaptchaResponse } from '../../../model/recaptcha.model';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [CommonModule, FormsModule, NzButtonModule, ToastrModule, HttpClientModule, NzFormModule, NzInputModule, NzInputNumberModule, NzSpinComponent, PageLoadingComponent, TranslateModule, RouterModule, RecaptchaModule]
})
export class LoginComponent implements OnInit {
  tryCount: number = 0;
  loading: any = false;
  errorMessage: any = '';
  form: any = {
    username: null,
    password: null
  };
  resetPasswordform: any = {
    resetPassword: null,
    confirmPassword: null
  };

  instructionForm: any = {
    emailOrUsername: null
  }

  roles: any = [];
  hide: any = 'true';
  showLogin = true;
  showReset = false;
  showSignIn = false;
  showCaptcha = false;

  errorSub: Subscription | undefined
  recaptchaResponse: string | null = null;
  captchaSiteKey = environment.captchaSiteKey;
  MAX_PASSWORD_TRIES: number = 3;
  @ViewChild('recaptchaRef') recaptchaComponent!: RecaptchaComponent;

  constructor(
    private readonly router: Router,
    private readonly authService: LoginAuthService,
    private readonly tokenService: TokenStorageService,
    private readonly translateService: TranslateService,
    private readonly pingOneService: PingOneService,
    private readonly message: NzMessageService
  ) { }

  ngOnInit(): void {
    if (this.tokenService.getToken()) {
      this.roles = this.tokenService.getUser().roles;
      this.loadLandingPage(this.tokenService.getUser());
    }
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
  }

  clearData = (): any => {
    this.form.username = '';
    this.form.password = '';
    this.errorMessage = '';
  }

  login = (): any => {
    const { username, password } = this.form;
    if (this.showCaptcha) {

      if (!this.recaptchaResponse) {
        this.translateService.get("Please select I'm not a robot", {}).subscribe((res: string) => {
          this.message.error(res);
        });
        return;
      }

      this.authService.verifyCaptcha(this.recaptchaResponse).subscribe({
        next: (response: RecaptchaResponse) => {
          this.loading = false;
          if (!response || !response.success) {
            this.translateService.get("Problem validating the captcha. Please refresh the page.", {}).subscribe((res: string) => {
              this.message.error(res);
            });
            return;
          }
          this.checkPassword(username, password);
        },
        error: (error: string) => {
          this.loading = false;
          this.translateService.get("Error in fetching data", {}).subscribe((res: string) => {
            this.message.error(res);
          });
        }
      });
    } else {
      this.checkPassword(username, password);
    }
  }

  checkPassword = (username: string, password: string): any => {
    if (password) {
      this.loading = true;
      this.authService.login(username, password).subscribe({
        next: (response: any) =>{
          this.tokenService.saveToken(response.accessToken);
          this.tokenService.saveRefreshToken(response.refreshToken);
          this.tokenService.saveUser(response);
          this.roles = this.tokenService.getUser().roles;
          this.loading = false;
          this.loadLandingPage(response);
        },
        error: (error: string) => {
          this.tryCount++;
          if (this.showCaptcha) {
            this.resetCaptcha();
          }
          if (!this.showCaptcha && this.tryCount >= this.MAX_PASSWORD_TRIES) this.showCaptcha = true;
          this.form.password = "";
          this.loading = false;
          this.translateService.get('Login failed', {}).subscribe((res: string) => {
            this.message.error(res);
          });
        }
    });
    } else {
      this.translateService.get('Password is required', {}).subscribe((res: string) => {
        this.message.error(res);
      });
    }
  }
  next = (): any => {
    const { username } = this.form;
    if (username) {
      if (username.trim().toLowerCase().endsWith("@apiglobalsolutions.com")) {
        const state = generateRandomValue();
        const nonce = generateRandomValue();
        this.pingOneService.authorize(state, nonce);
      } else {
        this.authService.getSSOUrl(username).subscribe({
          next: (response: any) => {
            if (response.data !== "") {
              document.location.href = response.data;
            } else {
              this.showSignIn = true;
            }
          },
          error: (error: string) => {
            this.form.username = "";
            this.loading = false;
            this.translateService.get('Login failed', {}).subscribe((res: string) => {
              this.message.error(res);
            });
          }
      });
      }
    } else {
      this.translateService.get('Email or username is required', {}).subscribe((res: string) => {
        this.message.error(res);
      });
    }
  }
  back = (): any => {
    this.showSignIn = false;
    this.tryCount = 0;
    this.resetCaptcha();
  }

  loadLandingPage = (data: any): void => {
    const lastPasswordChangedDate = moment(data.lastPasswordChangedDate, SERVER_DATE_FORMAT_CONSTANT);
    if (data.airline.baseAirportOnUserProfile && !data.baseAirport) {
      this.router.navigate(['/mccplus/myProfile']);
    } else if (!data.airline.initiateSso && moment().diff(lastPasswordChangedDate, "days") > PASSWORD_NOT_CHANGED_SINCE_DAYS) {
      this.router.navigate(['/mccplus/changePassword']);
    }


    if (this.authService.isAuthorized([USER_ROLE_CONSTANT.ADMINISTRATOR])) {
      this.router.navigate(['/mccplus/feedback']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_MEMBER]) && data.airline.myTravelLink == 1) {
      this.router.navigate(['/mccplus/myTravel']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.CREW_MEMBER])) {
      this.router.navigate(['/mccplus/featuredHotels']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_ADMIN])) {
      this.router.navigate(['/mccplus/feedback']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.EXTERNAL_REPORTS_USER])) {
      this.router.navigate(['/mccplus/featuredHotels']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.INTERNAL_ADMIN])) {
      this.router.navigate(['/mccplus/feedback']);
    } else if (this.authService.isAuthorized([USER_ROLE_CONSTANT.DIL_AGENT])) {
      this.router.navigate(['/mccplus/searchDailyIncidentLog']);
    } else {
      this.router.navigate(['/mccplus/feedback']);
    }
  }

  sendInstructions = (): any => {
    const { emailOrUsername } = this.instructionForm;
    if (emailOrUsername) {
      this.loading = true;
      this.authService.sendResetPasswordInstruction(emailOrUsername).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.loading = false;
            this.message.success(response.message);
            this.instructionForm.emailOrUsername = null;
            this.showLogin = true;
            this.showReset = false;
          }
        },
        error: (error: string) => {
          this.loading = false;
          this.translateService.get('Error in fetching data', {}).subscribe((res: string) => {
            this.message.error(res);
          });
        }
      });
     
    } else {
      this.message.error('Email or username is required');
    }
  }
  onCaptchaResolved(captchaResponse: string | null) {
    this.recaptchaResponse = captchaResponse;
  }
  resetCaptcha() {
    this.recaptchaComponent.reset(); 
    this.recaptchaResponse = null;
    this.showCaptcha = false;
  }
}
